import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import MyStakes from "./pages/MyStakes";
import Navbar from "./components/footer/Navbar";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { Web3Modal } from "@web3modal/react";
import { configureChains, createConfig, WagmiConfig } from "wagmi";
import { bsc } from "wagmi/chains";
import Footer from "./components/footer/Footer.js";

const chains = [bsc];
const projectId = "5fa71af7fd00f0c675f369d6e1b4a799";
const { publicClient, webSocketPublicClient } = configureChains(chains, [
  w3mProvider({ projectId }),
]);
const wagmiConfig = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
  webSocketPublicClient,
});

function App() {
  const ethereumClient = new EthereumClient(wagmiConfig, chains);

  return (
    <div>
            <Router>
      <WagmiConfig config={wagmiConfig}>
        <section className="wrapper">
          <Navbar client={ethereumClient} />
          <main style={{ flex: "auto" }}>
              <Routes>
                <Route
                  exact
                  path="/"
                  element={<Home client={ethereumClient} />}
                />
                <Route
                  exact
                  path="/mystakes"
                  element={<MyStakes client={ethereumClient} />}
                />
              </Routes>
          </main>
          <Footer />
        </section>
      </WagmiConfig>
      <Web3Modal projectId={projectId} ethereumClient={ethereumClient} />
            </Router>
    </div>
  );
}

export default App;
