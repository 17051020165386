import { Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import Block3 from "./confirmStake";
import { stakeapy } from "../../constants/constant";
const Block2 = ({
  setDialog2,
  depositAmount,
  client,
  setOpen,
  setDepositAmount,
  tradedAmount,
}) => {
  const [plan, setPlan] = useState(0);
  const [dialog3, setDialog3] = useState(false);

  const handleClose2 = () => {
    setDialog2(false);
    setOpen(false);
  };

  const handleBack2 = () => {
    setDialog2(false);
  };

  const handleDialog3Open = () => {
    setDialog3(true);
  };

  return (
    <div
      className="h-auto md:w-[550px] w-[280px]  md:p-5 p-3"
      style={{ background: "linear-gradient(90deg, #000 0%, #8437FA 230.49%)" }}
    >
      <div className="flex justify-between items-center">
        <p className="md:text-[24px] text-[16px]">Stake your token</p>
        <Close
          onClick={handleClose2}
          sx={{ color: "white", cursor: "pointer" }}
        />
      </div>
      <div
        className="mt-10 flex justify-between pb-2"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="flex gap-2 items-center md:pr-5 pr-2"
          style={{ borderRight: "1px solid white" }}
        >
          <img
            src="/img/pop2img1.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Amount</p>
            <p className="md:text-[12px] text-[8px]">{depositAmount.toFixed(2)}</p>
          </div>
        </div>
        <div
          className="flex gap-2 items-center md:pr-5 pr-2"
          style={{ borderRight: "1px solid white" }}
        >
          <img
            src="/img/pop1img2.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Duration</p>
            <p className="md:text-[12px] text-[8px]"></p>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <img
            src="/img/pop1img3.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Review</p>
            <p className="md:text-[12px]text-[8px]"></p>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <p className="font-[500]">How long would you like to stake?</p>
        <p className="text-[12px] mt-2">
          Choose terms that fit your financial goals. You can claim rewards at any time.
        </p>
      </div>
      <div className="w-[100%] flex md:flex-row flex-col justify-between mt-10 gap-4 md:gap-0">
        <div className="md:w-[45%] w-full flex flex-col h-auto gap-5">
          <div
            className={`h-[60px] border-[0.25px] ${
              plan === 0 ? "border-[#8437fa]" : "border-white"
            } p-2 rounded-bl-[20px] cursor-pointer`}
            onClick={() => setPlan(0)}
          >
            <p>{stakeapy[0]}%</p>
            <div className="flex justify-between">
              <p className="text-[8px]">3 Months</p>
              <p className="text-[8px]">Annual percentage yield</p>
            </div>
          </div>
          <div
            className={`h-[60px] border-[0.25px] ${
              plan === 1 ? "border-[#8437fa]" : "border-white"
            } p-2 rounded-bl-[20px] cursor-pointer`}
            onClick={() => setPlan(1)}
          >
            <p>{stakeapy[1]}%</p>
            <div className="flex justify-between">
              <p className="text-[8px]">6 Months</p>
              <p className="text-[8px]">Annual percentage yield</p>
            </div>
          </div>
        </div>
        <div className="md:flex flex-col justify-center items-center gap-2 hidden">
          <div className="h-[50px] w-[2px] bg-white"></div>
          <div className="h-[15px] w-[15px] bg-[#8437FA] rounded-[10px]"></div>
          <div className="h-[50px] w-[2px] bg-white"></div>
        </div>
        <div className="md:w-[45%] w-full flex flex-col h-auto gap-5">
          <div
            className={`h-[60px] border-[0.25px] ${
              plan === 2 ? "border-[#8437fa]" : "border-white"
            } p-2 rounded-bl-[20px] cursor-pointer`}
            onClick={() => setPlan(2)}
          >
            <p>{stakeapy[2]}%</p>
            <div className="flex justify-between">
              <p className="text-[8px]">1 Year</p>
              <p className="text-[8px]">Annual percentage yield</p>
            </div>
          </div>

          <div
            className={`h-[60px] border-[0.25px] ${
              plan === 3 ? "border-[#8437fa]" : "border-white"
            } p-2 rounded-bl-[20px] cursor-pointer`}
            onClick={() => setPlan(3)}
          >
            <p>{stakeapy[3]}%</p>
            <div className="flex justify-between">
              <p className="text-[8px]">2 Years</p>
              <p className="text-[8px]">Annual percentage yield</p>
            </div>
          </div>
        </div>
      </div>
      <div
        onClick={handleDialog3Open}
        className="h-[40px] w-full border border-[#8437FA] mt-5 flex justify-center items-center cursor-pointer bg-[#8437FA]"
      >
        <p className="text-[12px]">Next</p>
      </div>
      <Dialog open={dialog3}>
        <Block3
          setDialog3={setDialog3}
          plan={plan}
          depositAmount={depositAmount}
          client={client}
          handleClose2={handleClose2}
          setDepositAmount={setDepositAmount}
          tradedAmount={tradedAmount}
        />
      </Dialog>
      <div
        onClick={handleBack2}
        className="h-[40px] w-full border border-[#8437FA] mt-2 flex justify-center items-center cursor-pointer"
      >
        <p className="text-[12px]">Back</p>
      </div>
    </div>
  );
};

export default Block2;
