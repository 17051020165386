import React, { useState } from "react";
import {
  stakingAddress,
  stakingABI,
  rpcURL,
  briseDecimal,
  stakeapy,
  beFeDecimal,
} from "../../constants/constant";
import { Dialog } from "@mui/material";
import Block6 from "../popup/unStake";
import Block5 from "../popup/claimReward";
import Web3 from "web3";

const bscweb3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
const stakingContract = new bscweb3.eth.Contract(stakingABI, stakingAddress);

const Block2 = ({ client }) => {
  const [Data, setData] = useState(Array);
  const [dialog5, setDialog5] = useState(false);
  const [dialog6, setDialog6] = useState(false);
  const [targetIndex, setTargetIndex] = useState(0);
  const [targetClaimable, setTargetClaim] = useState(0);
  const [targetStakedAmount, setTargetStakedAmount] = useState(0);
  const [targetUnstakeDate, setTargetUnstakeDate] = useState();
  const [targetDuration, setTargetDuration] = useState(0);

  var connectedAddress, stakingData;
  try {
    connectedAddress = client.getAccount().address;
  } catch (err) {}

  const unixTimestampToDate = (unixTimestamp) => {
    // Multiply by 1000 because JavaScript works in milliseconds instead of seconds
    const milliseconds = unixTimestamp * 1000;

    // Create a new Date object using the milliseconds
    const dateObject = new Date(milliseconds);

    // Extract the various date components
    const year = dateObject.getFullYear();
    const month = dateObject.getMonth() + 1; // Months are zero-based
    const day = dateObject.getDate();

    const hours = dateObject.getHours();
    const minutes = dateObject.getMinutes();
    const seconds = dateObject.getSeconds();
    // Use the extracted components to create a formatted date string
    var formattedDate;
    if (day === 0) {
      formattedDate = `${hours}:${minutes}:${seconds}`;
    } else {
      formattedDate = `${day}/${month}/${year}`;
    }

    return formattedDate;
  };

  const getStakingData = async () => {
    try {
      stakingData = await stakingContract.methods
        .getUserStakes(connectedAddress)
        .call();

      for (let index = 0; index < stakingData.length; index++) {
        var rewardable
        try{
          rewardable = await stakingContract.methods
          .getUserRewards(connectedAddress, index)
          .call();
        } catch(err){
        }
        stakingData[index]["reward"] = Number(rewardable);

        stakingData[index]["UnlockTime"] = unixTimestampToDate(
          Number(stakingData[index].minimumStakeTimestamp)
        );

        if (Number(stakingData[index].duration) === 7776000) {
          stakingData[index]["DurationLabel"] = "3 Months";
          stakingData[index]["apy"] = stakeapy[0] + "%";
        } else if (Number(stakingData[index].duration) === 15552000) {
          stakingData[index]["DurationLabel"] = "6 Months";
          stakingData[index]["apy"] = stakeapy[1] + "%";
        } else if (Number(stakingData[index].duration) === 31104000) {
          stakingData[index]["DurationLabel"] = "1 Year";
          stakingData[index]["apy"] = stakeapy[2] + "%";
        } else if (Number(stakingData[index].duration) === 62208000) {
          stakingData[index]["DurationLabel"] = "2 Years";
          stakingData[index]["apy"] = stakeapy[3] + "%";
        }

        var timeLeft =
          parseInt(
            (Number(stakingData[index].minimumStakeTimestamp) -
              Math.floor(Date.now() / 1000)) /
              86400
          ) + " Days";
        stakingData[index]["timeLeft"] = timeLeft;
      }
      setData(stakingData);
    } catch (err) {}
  };

  const handleDialog5Open = (index) => {
    setTargetIndex(index);
    setTargetClaim(Data[index].reward / Math.pow(10, beFeDecimal));
    setDialog5(true);
  };

  const handleDialog6Open = (index) => {
    setTargetIndex(index);
    setTargetStakedAmount(Number(Data[index].stakedAmount));
    setTargetUnstakeDate(Data[index].UnlockTime);
    setTargetDuration(Data[index].duration);
    setDialog6(true);
  };

  getStakingData();

  return (
    <div
      className="w-[100%] flex justify-center mt-10"
      style={{ height: "100%" }}
    >
      <div className="w-[90%]">
        <p className="text-white lg:text-[30px] md:text-[24px] text-[16px] font-[500]">
          My Stakes
        </p>
        <div className="w-[100%] border border-white lg:h-auto md:h-auto h-auto mt-5 rounded-br-[50px] pb-10">
          <div className="flex items-center md:mt-[50px] gap-3 md:mb-[50px] mt-[30px] mb-[30px]">
            <div className="border-[0.25px] border-white md:p-3 p-2 md:w-[74px] w-[44px] md:ml-[50px] ml-[30px]">
              <img
                src="/img/account.png"
                className="md:h-[40px] md:w-[40px] h-[24px] w-[24px]"
                alt="brise"
              />
            </div>
            <div>
              <p className="lg:text-[34px] md:text-[24px] text-[16px] font-[500]">
                Overview
              </p>
            </div>
          </div>
          <div className="w-full flex justify-center items-center gap-[50px] flex-wrap">
            {Data &&
              Data.length > 0 &&
              Data.map((value, index) => {
                return (
                  <div className="border-[0.25px] border-white md:w-[380px] md:h-auto w-[248px] h-auto p-10">
                    <p className="text-[24px] font-[600]">Staking $BRISE</p>
                    <div className="flex justify-between">
                      <p className="md:text-[16px] text-[12px] font-[400]">
                        Stake Amount
                      </p>
                      <p className="md:text-[18px] text-[12px] font-[400]">
                        {Number(value.stakedAmount) /
                          Math.pow(10, briseDecimal)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-[16px] text-[12px] font-[400]">
                        Duration
                      </p>
                      <p className="md:text-[18px] text-[12px] font-[400]">
                        {value.DurationLabel}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-[16px] text-[12px] font-[400]">
                        Unlock Timestamp
                      </p>
                      <p className="md:text-[18px] text-[12px] font-[400]">
                        {value.UnlockTime}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-[16px] text-[12px] font-[400]">
                        Earned
                      </p>
                      <p className="md:text-[18px] text-[12px] font-[400]">
                        {(value.reward / Math.pow(10, beFeDecimal)).toFixed(10)}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-[16px] text-[12px] font-[400]">
                        APY
                      </p>
                      <p className="md:text-[18px] text-[12px] font-[400]">
                        {value.apy}
                      </p>
                    </div>
                    <div className="flex justify-between">
                      <p className="md:text-[16px] text-[12px] font-[400]">
                        Time Left
                      </p>
                      <p className="md:text-[18px] text-[12px] font-[400]">
                        {value.timeLeft}
                      </p>
                    </div>
                    <div
                      onClick={() => handleDialog6Open(index)}
                      className="h-[40px] w-full border border-[#8437FA] mt-5 flex justify-center items-center cursor-pointer"
                    >
                      <p className="text-[14px]">Unstake</p>
                    </div>
                    <Dialog open={dialog6}>
                      <Block6
                        setDialog6={setDialog6}
                        targetDuration={targetDuration}
                        targetStakedAmount={targetStakedAmount}
                        targetIndex={targetIndex}
                        targetUnstakeDate={targetUnstakeDate}
                        getStakingData={getStakingData}
                      />
                    </Dialog>
                    <div
                      onClick={() => handleDialog5Open(index)}
                      className="h-[40px] w-full border border-[#8437FA] mt-2 flex justify-center items-center cursor-pointer"
                    >
                      <p className="text-[14px] ">
                        Claim $BEFE
                      </p>
                    </div>
                    <Dialog open={dialog5}>
                      <Block5
                        setDialog5={setDialog5}
                        getStakingData={getStakingData}
                        targetIndex={targetIndex}
                        targetClaimable={targetClaimable}
                      />
                    </Dialog>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block2;
