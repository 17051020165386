import React from "react";

const Block4 = () => {
  return (
    <div>
      <div className="testimonial">
        {/* <img src="img/Vector.svg" className="vector star" alt="star" />
        <img src="img/person.png" className="person" alt="person" />
        <h5>Digital Creator</h5>
        <h4>JACK AY</h4> */}
        <div className="w-[100%] flex justify-center">
          <div className="w-[95%] md:w-[60%]">
            <img
              src="img/coat1.png"
              className="h-[30px] w-[30px] md:h-auto md:w-auto"
              alt="coat1"
            />
          </div>
        </div>
        <p>
          {" "}
          BRISE serves as the utility token within the Bitgert Ecosystem. The
          staking program for BRISE was created to incentivize dedicated and
          unwavering BRISE holders. By staking your BRISE tokens, you can
          receive our MEME token, BEFE, as a reward. BEFE is characterized as a
          hyper-meme token.
        </p>

        <div className="w-[100%] flex justify-center mt-5">
          <div className="w-[95%] md:w-[60%] flex justify-end">
            <img
              src="img/coat2.png"
              className="h-[30px] w-[30px] md:h-auto md:w-auto"
              alt="coat1"
            />
          </div>
        </div>
      </div>
      <div className="communities">
        <div className="community">
          <div className="vector">
            <img src="img/vector_symbol.png" className="p_logo" alt="p_logo" />
            <p className="text-[18px] font-medium pl-3 md:text-[28px]">
              Join the Brise community for exciting updates!
            </p>
          </div>

          <div className="tele">
            <a
              className="border border-[#8437FA] rounded-br-[30px] flex p-[20px] w-full flex items-center"
              href="https://t.me/bitgertbrise"
              target="_blank"
            >
              <img
                src="img/telegram.png"
                alt=""
                className="w-[100px] h-[100px]"
                // style={{ width: "100px", height: "100px" }}
              />
              <p className="text-[22px] md:text-[34px] lg:text-[48px] font-semibold">
                Telegram
              </p>
            </a>

            <a
              className="border border-[#8437FA] rounded-br-[30px] flex p-[20px] w-full flex items-center"
              href="https://x.com/bitgertbrise"
              target="_blank"
            >
              <img
                src="img/twitter3.png"
                alt=""
                className="w-[100px] h-[100px]"
                // style={{ width: "100px", height: "100px" }}
              />
              <p className="text-[22px] md:text-[34px] lg:text-[48px] font-semibold">
                X
              </p>
            </a>
          </div>
        </div>
      </div>
      <br />
    </div>
  );
};

export default Block4;
