import React, { useEffect, useState } from "react";
import { Web3Button } from "@web3modal/react";
import "../../index.css";
import { Link, useLocation } from "react-router-dom";

const Navbar = (props) => {
  const [svgFlag, setSVGFlag] = useState(false);

  const location = useLocation()

  const toggleNavBar = (e) => {
    setSVGFlag(!svgFlag);
  };

  useEffect(() => {
    if (location.pathname.includes("/mystakes")) setSVGFlag(true);
    else if (
      location.pathname === "/" ||
      location.pathname === ""
    )
      setSVGFlag(false);
  }, [location]);

  return (
    <div
      className="w-[100%] flex md:flex-row flex-col justify-center items-center pb-5"
      style={{ borderBottom: "1px solid rgba(255, 255, 255, 0.10)" }}
    >
      <div className="w-[90%] flex justify-between md:flex-row flex-col items-center md:p-[0px 40px] mt-5">
        <div className="flex items-center justify-between w-full">
          <div className="flex flex-row items-center justify-between md:w-[25%] w-full">
            <a href="/" className="text-white" onClick={toggleNavBar}>
              <img src="img/logo.png" alt="logo" className="logo" />
            </a>
            <div className="flex items-center gap-4">
              <div className="flex flex-col justify-center items-baseline">
                <Link to="/" className="text-white" 
                onClick={toggleNavBar}>
                  Home
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="49"
                  height="5"
                  viewBox="0 0 49 5"
                  fill="none"
                  className={!svgFlag ? "block" : "hidden"}
                >
                  <path
                    d="M1 3.99992C14.1667 1.49992 42 -2.00008 48 3.99992"
                    stroke="#8437FA"
                  />
                </svg>
              </div>
              <div className="flex flex-col justify-center items-baseline">
                <Link
                  to="/mystakes"
                  className="text-white"
                  onClick={toggleNavBar}
                >
                  My Stakes
                </Link>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="80"
                  height="5"
                  viewBox="0 0 49 5"
                  fill="none"
                  className={svgFlag ? "block" : "hidden"}
                >
                  <path
                    d="M1 3.99992C14.1667 1.49992 42 -2.00008 48 3.99992"
                    stroke="#8437FA"
                  />
                </svg>
              </div>
            </div>
          </div>
          <Web3Button className="flex  gap-4" />
        </div>
      </div>
    </div>
  );
};

export default Navbar;
