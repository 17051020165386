import { Dialog } from "@mui/material";
import React, { useState, useEffect } from "react";
import Block1 from "../popup/StakeAmountInput";
import { useAccount } from "wagmi";
import { ToastContainer, toast } from "react-toastify";
import { GoHome } from "react-icons/go";
import { CiStar } from "react-icons/ci";
import { HiOutlineBolt } from "react-icons/hi2";
import { FaBandcamp } from "react-icons/fa";
import "react-toastify/dist/ReactToastify.css";
import "./2stake.scss";

const notify = (isError, msg) => {
  if (isError) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

const Block2 = ({ client }) => {
  const { isConnected } = useAccount();
  const [clientData, setClientData] = useState(client);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setClientData(client);
  }, [client]);

  const handleOpen = () => {
    if (!isConnected) {
      notify(true, "Please connect your wallet");
      return;
    }
    setOpen(true);
  };

  return (
    <div>
      <div className="hero section">
        <div className="hero_left">
          <h1>Stake BRISE, Earn BEFE</h1>
          <p>
            {" "}
            Stake your BRISE tokens to earn Bitgert's meme/reward token, BEFE.
            Our user-friendly interface makes it simple and safe to stake,
            allowing you to start earning today.
          </p>
          <div style={{ display: "flex", gap: "40px" }}>
            <div>
              <button
                onClick={handleOpen}
                className="md:h-[60px] flex justify-center items-center p-5 h-[40px] text-[12px] md:text-[18px]"
                style={{
                  border: "1px solid #8437FA",
                  borderRadius: "0px 0px 20px 0px",
                  backgroundColor: "#8437FA",
                  color: "white",
                  cursor: "pointer",
                }}
              >
                Get Started
              </button>

              <Dialog open={open}>
                <Block1 setOpen={setOpen} client={clientData} />
              </Dialog>
            </div>
          </div>
        </div>
        <div className="hero_right">
          <img src="img/hero.png" alt="" />
        </div>
        <ToastContainer />
      </div>

      <div
        className="communities"
        style={{ marginLeft: "60px", marginRight: "60px" }}
      >
        <div className="w-full flex justify-center items-center">
          <div className="border border-white h-auto  w-full  md:p-10 p-5 rounded-br-[60px]">
            <div>
              <p className="text-white md:text-[48px] text-[30px] font-[600]">
                Stake your BRISE tokens and earn BEFE
              </p>
            </div>
            <div className="mt-[40px]">
              <p className="text-[white] text-[20px] font-[600]">
                It's as easy as 1,2,3
              </p>
            </div>
            <div className="mt-[40px] font-[30px] flex justify-evenly md:flex-row flex-col gap-7">
              <div className="w-[250px] ">
                <GoHome className="text-white " style={{ fontSize: "40px" }} />
                <p className="text-[25px] font-[700] mt-2">Get Started</p>
                <p className="text-white text-[14px] font-medium">
                  Connect your wallet
                </p>
              </div>
              <div className="w-[250px]">
                <CiStar className="text-white" style={{ fontSize: "40px" }} />
                <p className="text-[25px] font-[700] mt-2">
                  Stake Amount
                </p>
                <p className="text-white text-[14px] font-medium">
                  Determine the amount of BRISE
                </p>
                <p className="text-white text-[14px] font-medium">
                  you want to stake.
                </p>
              </div>

              <div className="w-[250px]">
                <FaBandcamp
                  className="text-white"
                  style={{ fontSize: "40px" }}
                />
                <p className="text-[25px] font-[700] mt-2">Choose a Plan</p>
                <p className="text-white text-[14px]">
                  Choose duration of your stake.
                </p>
              </div>
              <div className="w-[250px]">
                <HiOutlineBolt
                  className="text-white"
                  style={{ fontSize: "40px" }}
                />
                <p className="text-[25px] font-[700] mt-2">Earn Rewards</p>
                <p className="text-white text-[14px]">Earn rewards in BEFE.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block2;
