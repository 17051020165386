import React, { useState, useEffect } from "react";

import Block1 from "../contents/home/1rewardShow";
import Block2 from "../contents/home/2stake";
import Block3 from "../contents/home/3stakeShow";
import Block4 from "../contents/home/communityShow";

const Home = ({ client }) => {
  const [clientData, setClientData] = useState(client);

  useEffect(() => {
    setClientData(client);
  }, [client]);

  return (
    <div>
      <Block1 />
      <Block2 client={clientData} />
      <Block3 client={clientData} />
      <Block4 />
    </div>
  );
};

export default Home;
