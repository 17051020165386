import { Close } from "@mui/icons-material";
import { Dialog } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Block2 from "./tireSelect";
import Web3 from "web3";
import {
  briseDecimal,
  briseTokenAddress,
  ERC20ABI,
  rpcURL,
  stakingABI,
  stakingAddress,
} from "../../constants/constant";

const notify = (isError, msg) => {
  if (isError) {
    toast.error(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.success(msg, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
};

const bscweb3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
const briseContract = new bscweb3.eth.Contract(ERC20ABI, briseTokenAddress);
const stakingContract  = new bscweb3.eth.Contract(stakingABI, stakingAddress)

const Block1 = ({ setOpen, client }) => {
  const [walletBalance, setWalletBalance] = useState(0);
  const [depositAmount, setDepositAmount] = useState(0);
  const [dialog2, setDialog2] = useState(false);
  const [minLimit, setMinLimit] = useState(0);
  const [maxLimit, setMaxLimit] = useState(0);
  const inputRef = useRef(null);

  useEffect(() => {
    if (client != null) {
      getBalance(client.getAccount().address);
    }
  }, [client]);

  const getBalance = async (addr) => {
    var Balance, min, max;
    try {
      Balance = await briseContract.methods.balanceOf(addr).call();
      setWalletBalance(Number(Balance) / Math.pow(10, briseDecimal));
      min = await stakingContract.methods.stakeMINLimitAmount().call();
      max = await stakingContract.methods.stakeMAXLimitAmount().call();
      setMaxLimit(Number(max));
      setMinLimit(Number(min));
    } catch (err) {}
  };

  const setMaxBalance = async () => {
    setDepositAmount(walletBalance);
    inputRef.current.value = walletBalance;
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleDialog2Open = () => {
    if (!Number(depositAmount)) {
      notify(true, "Please input Correctly");
      return;
    }

    if (depositAmount < minLimit / Math.pow(10,briseDecimal)){
      notify(true, " Amount is smaller than MIN Limit");
      return;
    }

    if (depositAmount > maxLimit / Math.pow(10,briseDecimal)){
      notify(true, " Amount is bigger than MAX Limit");
      return;
    }

    if (depositAmount > walletBalance) {
      notify(true, "Insufficient Token Balance");
      return;
    }
    setDialog2(true);
  };

  const handleDepositAmount = (e) => {
    setDepositAmount(Number(e.target.value));
  };

  return (
    <div
      className="h-auto md:w-[500px] w-[280px]  md:p-5 p-3"
      style={{ background: "linear-gradient(90deg, #000 0%, #8437FA 230.49%)" }}
    >
      <div className="flex justify-between items-center">
        <p className="md:text-[24px] text-[16px]">Stake your token</p>
        <Close
          onClick={handleClose}
          sx={{ color: "white", cursor: "pointer" }}
        />
      </div>
      <div
        className="mt-10 flex justify-between pb-2"
        style={{ borderBottom: "1px solid white" }}
      >
        <div
          className="flex gap-2 items-center md:pr-5 pr-2"
          style={{ borderRight: "1px solid white" }}
        >
          <img
            src="/img/pop1img1.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Amount</p>
            <p className="md:text-[12px] text-[8px]"></p>
          </div>
        </div>
        <div
          className="flex gap-2 items-center md:pr-5 pr-2"
          style={{ borderRight: "1px solid white" }}
        >
          <img
            src="/img/pop1img2.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Duration</p>
            <p className="md:text-[12px] text-[8px]"></p>
          </div>
        </div>
        <div className="flex gap-2 items-center">
          <img
            src="/img/pop1img3.png"
            className="md:h-[50px] md:w-[50px] h-[25px] w-[25px]"
            alt="brise"
          />
          <div>
            <p className="font-[500] text-[16px] md:text-[18px]">Review</p>
            <p className="md:text-[12px]text-[8px]"></p>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <p className="font-[500]">How much would you like to stake?</p>
        <p className="text-[12px] mt-2">
        Brise's staking platform is fully audited and our staking contracts 
        are monitored 24/7. Know the risks of staking and 
        choose an amount that fits your plan.
        </p>
      </div>
      <div className="h-[100px] w-full border border-white mt-10 p-2 ">
        <div className="flex justify-between items-center">
          <input
            type="text"
            ref={inputRef}
            defaultValue={depositAmount}
            placeholder="0"
            onChange={handleDepositAmount}
            className="text-[16px] font-[500] text-[#8437FA]"
            style={{ backgroundColor: "transparent", marginLeft: "10px" }}
          />
        </div>
        <div className="flex flex-col items-end">
          <p className="text-[14px]">
            Balance : {walletBalance.toFixed(4)} BRISE
          </p>
          <button onClick={setMaxBalance}>
            <p className="text-[16px] font-[500] text-[#8437FA]">MAX</p>
          </button>
          <p className="text-[12px] font-[500]">Stake Amount Range : {(minLimit / Math.pow(10,9))} ~ {(maxLimit/ Math.pow(10,9))}</p>
        </div>
      </div>
      <div
        onClick={handleDialog2Open}
        className="h-[40px] w-full border border-[#8437FA] mt-2 flex justify-center items-center cursor-pointer"
      >
        <p className="text-[12px] ">Next</p>
      </div>
      <ToastContainer />
      <Dialog open={dialog2}>
        <Block2
          setDialog2={setDialog2}
          depositAmount={depositAmount}
          client={client}
          setOpen={setOpen}
          setDepositAmount={setDepositAmount}
          tradedAmount={walletBalance}
        />
      </Dialog>
    </div>
  );
};

export default Block1;
