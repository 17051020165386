import { Close } from "@mui/icons-material";
import React from "react";

const Block4 = ({ setDialog4, hash }) => {
  const handleDialog4Close = () => {
    setDialog4(false);
  };
  return (
    <div>
      <div
        className="h-auto md:w-[500px] w-[280px]  md:p-5 p-3"
        style={{
          background: "linear-gradient(90deg, #000 0%, #8437FA 230.49%)",
        }}
      >
        <div className="flex justify-between items-center">
          <p className="md:text-[24px] text-[16px]">Stake Your Token</p>
          <Close
            onClick={handleDialog4Close}
            sx={{ color: "white", cursor: "pointer" }}
          />
        </div>
        <div className="mt-10 ">
          <p className="text-[20px] font-[500]">Overview</p>
        </div>
        <div className="flex justify-center items-center md:h-[300px] h-[150px] w-full flex-col">
          <img
            src="/img/account.png"
            className="md:h-[200px] md:w-[200px] h-[120px] w-[120px]"
            alt="brise"
          />
          <p className="text-[24px] font-[500]">
            Congratulation! You are all set !
          </p>
        </div>
        <a
          href="/mystakes"
          className="h-[40px] w-full border border-[#8437FA] mt-5 flex justify-center items-center cursor-pointer"
        >
          <p className="text-[12px]">See stakes</p>
        </a>
        <div className="h-[40px] w-full border border-[#8437FA] mt-2 flex justify-center items-center cursor-pointer">
          <a
            className="text-[12px] "
            href={"https://bscscan.com/tx/" + hash}
            target="_blank"
            rel="noreferrer"
            style={{ color: "white" }}
          >
            View Transaction
          </a>
        </div>
      </div>
    </div>
  );
};

export default Block4;
