import React from "react";

const Block1 = () => {
  return (
    <div
      className="w-[100%] flex justify-center mt-10"
      style={{ height: "100%" }}
    >
      <div className="w-[90%]">
        <p className="text-white lg:text-[30px] md:text-[24px] text-[16px] font-[500]">
          My Stakes
        </p>
        <div className="w-[100%] border border-white lg:h-[760px] md:h-[680px] h-[480px] mt-5 rounded-br-[50px]">
          <div className="flex items-center md:mt-[50px] gap-3 md:mb-[50px] mt-[30px] mb-[30px]">
            <div className="border-[0.25px] border-white md:p-3 p-2 md:w-[74px] w-[44px] md:ml-[50px] ml-[30px]">
              <img
                src="/img/account.png"
                className="md:h-[40px] md:w-[40px] h-[24px] w-[24px]"
                alt="brise"
              />
            </div>
            <div>
              <p className="lg:text-[34px] md:text-[24px] text-[16px] font-[500]">
                Overview
              </p>
            </div>
          </div>
          <div className="w-full flex justify-center items-center">
            <div className="md:w-[80%] lg:w-[60%] w-[90%] border border-white lg:h-[460px] md:h-[456px] h-[317px] flex justify-center items-center flex-col">
              <img
                src="/img/dice.png"
                className="lg:h-[160px] lg:w-[160px] md:h-[100px] md:w-[100px] h-[80px] w-[80px]"
                alt="brise"
              />
              <p className="text-[#FFF] lg:text-[48px] md:text-[32px] text-[16px] font-medium">
                Wallet not Connected
              </p>
              <p className="text-[#FFF] lg:text-[24px] md:text-[16px] text-[8px] font-medium">
                Please connect your wallet first
              </p>

              <div className="mt-10">
                <a
                  href="/"
                  className="md:h-[60px] w-auto flex justify-center items-center p-5 h-[40px] text-[12px] md:text-[18px]"
                  style={{
                    border: "1px solid #8437FA",
                    borderRadius: "0px 0px 20px 0px",
                    backgroundColor: "#8437FA",
                    color: "white",
                    marginRight: 5,
                  }}
                >
                  Connect Wallet
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Block1;
