import React from "react";

const Footer = () => {
  return (
    <div>
      <div className="p-[40px] md:p-[60px] md:flex w-[100%] md:justify-between">
        <div className="md:w-[40%] w-full ">
          <p className="md:mb-[100px] mb-[50px] text-base">
            The Brise staking program was designed to reward loyal, diamond
            handed BRISE holders. When you stake your BRISE tokens, you will
            earn our sister token named Befe as a reward.
          </p>
          <img src="img/logo.png" alt="" className="mb-[50px] md:mb-0" />
        </div>
        <div className="md:flex md:justify-evenly md:w-[50%]">
          <div className="flex  justify-between mb-10 md:gap-[100px]">
            <div className="legal">
              <p className="md:text-[24px] text-[20px] font-medium">Legal</p>
              <ul>
                <li>
                  <a
                    href="https://bitgert.com/risk-disclosure-statement/"
                    className="text-white text-[12px]  md:text-[16px]"
                  >
                    {" "}
                    Risk Disclosure
                  </a>
                </li>
                <li>
                  <a
                    href="https://bitgert.com/terms-conditions/"
                    className="text-white text-[12px]  md:text-[16px]"
                  >
                    Terms & Conditions
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
