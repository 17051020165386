import React, { useState } from "react";
import Web3 from "web3";
import {
  multiplierAddress,
  multiplierABI,
  rpcURL,
  stakeapy,
} from "../../constants/constant";

const bscweb3 = new Web3(new Web3.providers.HttpProvider(rpcURL));

const Block1 = () => {
  const [rewardData, setRewardData] = useState("");
  const getRewardData = async () => {
    try {
      const multiplierContract = new bscweb3.eth.Contract(
        multiplierABI,
        multiplierAddress
      );
      const rewardBufferData = await multiplierContract.methods
        .getDurationThresholds()
        .call();
      setRewardData("3 Months "+stakeapy[0]+" %, 6 Months "+stakeapy[1]+" %, 1 Years "+stakeapy[2]+" %, 2 Years "+ stakeapy[3]+" %,");
    } catch (err) {}
  };

  getRewardData();

  return (
    <div>
      <div className="percent">
        <marquee style={{ color: "#fff" }}>
          <span style={{ paddingRight: "90px" }}>Staking APY  : {rewardData} </span>
        </marquee>
      </div>
    </div>
  );
};

export default Block1;
