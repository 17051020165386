import React, { useState } from "react";
import Web3 from "web3";
import {
  stakingAddress,
  stakingABI,
  briseDecimal,
  rpcURL,
  routerAddress,
  routerABI,
  briseTokenAddress,
  busdAddress,
} from "../../constants/constant";
import { Typography } from "@mui/material";

const bscweb3 = new Web3(new Web3.providers.HttpProvider(rpcURL));
const routerContract = new bscweb3.eth.Contract(routerABI, routerAddress);

const Block3 = () => {
  const [stakeAmount, setStakeAmount] = useState("");
  const [stakeAmountUSD, setStakeAmountUSD] = useState("");

  const getStakeAmount = async () => {
    try {
      const stakingContract = new bscweb3.eth.Contract(
        stakingABI,
        stakingAddress
      );
      const totalStaked = await stakingContract.methods.totalStaked().call();
      var price = await routerContract.methods
        .getAmountsOut((1000000000).toString(), [
          briseTokenAddress,
          busdAddress,
        ])
        .call();
      price = Number(price[1]) / Math.pow(10, 18);

      var buffer = Number(totalStaked) / Math.pow(10, briseDecimal);
      var totalStakedUSD = price * buffer;

      var StakedTotal = "123";
      var stakedTotalUSD = "";

      if (totalStakedUSD >= 0 && totalStakedUSD < 1000) {
        stakedTotalUSD = totalStakedUSD;
      } else if (totalStakedUSD >= 1000 && totalStakedUSD < 1000000) {
        stakedTotalUSD = "+" + parseInt(totalStakedUSD / 1000) + "K";
      } else if (totalStakedUSD >= 1000000 && totalStakedUSD < 1000000000) {
        stakedTotalUSD = "+" + parseInt(totalStakedUSD / 1000000) + "M";
      } else if (
        totalStakedUSD >= 1000000000 &&
        totalStakedUSD < 1000000000000
      ) {
        stakedTotalUSD = "+" + parseInt(totalStakedUSD / 1000000000) + "B";
      } else if (
        totalStakedUSD >= 1000000000000 &&
        totalStakedUSD < 1000000000000000
      ) {
        stakedTotalUSD = "+" + parseInt(totalStakedUSD / 1000000000000) + "T";
      }
      setStakeAmountUSD(stakedTotalUSD);

      if (buffer >= 0 && buffer < 1000) {
        StakedTotal = buffer;
      } else if (buffer >= 1000 && buffer < 1000000) {
        StakedTotal = "+" + parseInt(buffer / 1000) + "K";
      } else if (buffer >= 1000000 && buffer < 1000000000) {
        StakedTotal = "+" + parseInt(buffer / 1000000) + "M";
      } else if (buffer >= 1000000000 && buffer < 1000000000000) {
        StakedTotal = "+" + parseInt(buffer / 1000000000) + "B";
      } else if (buffer >= 1000000000000 && buffer < 1000000000000000) {
        StakedTotal = "+" + parseInt(buffer / 1000000000000) + "T";
      }
      setStakeAmount(StakedTotal);
    } catch (err) {}
  };

  getStakeAmount();

  return (
    <div>
      <div className="priority">
        <div className="prio_left">
          <h1>We prioritize your safety and convenience above all else.</h1>
          <p className="text-base">
            The BRISE staking program was created to prioritize both security
            and user experience, guaranteeing a smooth and secure staking
            process.
          </p>
        </div>
        <div className="prio_right">
          <div
            className="div"
            style={{
              border: "1px solid #FFFFFF",
              borderRadius: "0px 0px 20px 0px",
              backgroundColor: "#1D1724",
            }}
          >
            <img src="img/prio_1.png" alt="" />
            <div>
              <h3>200,000+</h3>
              <p>HOLDERS</p>
            </div>
          </div>
          <div
            className="div"
            style={{
              border: "1px solid #FFFFFF",
              borderRadius: "0px 0px 0px 20px",
              backgroundColor: "#1D1724",
            }}
          >
            <div>
              <img src="img/prio_2.png" alt="" />
            </div>
            <div>
              <h3>100M+</h3>
              <p>REACH</p>
            </div>
          </div>
          <div
            className="div"
            style={{
              border: "1px solid #FFFFFF",
              borderRadius: "0px 0px 20px 0px",
              backgroundColor: "#1D1724",
            }}
          >
            <div>
              <img src="img/prio_3.png" alt="" />
            </div>
            <div style={{ textOverflow: "ellipsis", overflow: "hidden" }}>
              <Typography
                component="h3"
                sx={{
                  "&::scrollbar": {
                    display: "none",
                  },
                }}
                style={{
                  wordBreak: "keep-all",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                  overflow: "auto",
                }}
              >
                {stakeAmount} ($ {Number(stakeAmountUSD).toFixed()}){" "}
              </Typography>
              <p>$BRISE STAKED</p>
            </div>
          </div>
        </div>
        <img src="img/Bitcoin_front 1.png" className="bitcoin" alt="bitcoin" />
      </div>
    </div>
  );
};

export default Block3;
