import React from "react";
import { useAccount } from "wagmi";

import Block1 from "../contents/myStakes/notConnected";
import Block2 from "../contents/myStakes/stakeShow";

const MyStakes = ({ client }) => {
  const { isConnected } = useAccount();
  return (
    <div>
      {!isConnected ? <Block1 client={client} /> : null}
      {isConnected ? <Block2 client={client} /> : null}
    </div>
  );
};
export default MyStakes;
